import { createContext, ReactNode, useContext, useState } from "react";

const LocaleContext = createContext<{
	locale: string;
	setLocale: (locale: string) => void;
}>({});

export function LocaleProvider({
	children,
	locale,
}: {
	children: ReactNode;
	locale: string;
}) {
	const [value, setValue] = useState(locale);
	return (
		<LocaleContext.Provider value={{ locale: value, setLocale: setValue }}>
			{children}
		</LocaleContext.Provider>
	);
}

export default function useLocale() {
	return useContext(LocaleContext);
}
